import React, { useState } from "react";
import styles from "./Register.module.css";
import { baseUrl } from "./../../App";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios

const Register = (props) => {
  const navigate = useNavigate();
  const [variables, setVariables] = useState({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  });
  // const [errors, setErrors] = useState({});

  // make a network request to the server to register the user
  // const headers = {
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "http://localhost:3000",
  // };

  const registerUser = () => {
    axios
      .post(`${baseUrl}/api/register`, variables)
      .then((response) => {
        if (response.data) {
          navigate("/login");
        }
      })
      .catch((error) => console.log(error));
  };

  const submitRegisterForm = (e) => {
    e.preventDefault();
    registerUser();
  };

  return (
    <div className={styles.container}>
      <h2>Register</h2>
      <form onSubmit={submitRegisterForm}>
        <label>
          Username:
          <input
            type="text"
            value={variables.username}
            onChange={(e) =>
              setVariables({ ...variables, username: e.target.value })
            }
          />
        </label>
        <br />
        <label>
          Email:
          <input
            type="text"
            value={variables.email}
            onChange={(e) =>
              setVariables({ ...variables, email: e.target.value })
            }
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            value={variables.password}
            onChange={(e) =>
              setVariables({ ...variables, password: e.target.value })
            }
          />
        </label>
        <br />
        {/* <label>
          confirmPassword:
          <input
            type="password"
            value={variables.confirmPassword}
            onChange={(e) => setVariables({ confirmPassword: e.target.value })}
          />
        </label> */}
        <br />
        <button className="btn btn-outline-danger btn-sm" type="submit">
          Register
        </button>
        <button
          className="btn btn-outline-danger btn-sm"
          onClick={() => {
            navigate("/login");
          }}
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default Register;
