// Login.jsx
import React, { useState } from "react";
import styles from "./Login.module.css";
import axios from "axios";
import { baseUrl } from "./../../App";
import { useAuthDispatch } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useAuthDispatch();
  const token = localStorage.getItem("token") || null;
  const headers = new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  });
  const submitLoginForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const { data } = await axios.post(
        `${baseUrl}/api/login`,
        {
          username,
          password,
        },
        { headers: headers }
      );
      navigate("/");

      if (data) {
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("token", JSON.stringify(data.token));
        dispatch({ type: "LOGIN", payload: data });
      }
    } catch (err) {
      setError(err.response);
    }

    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <h2>ChatBubble</h2>
      <form onSubmit={submitLoginForm}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Se connecter </button>
      </form>
      <div className={styles.logininfo}>
        <p>Inscrivez-vous pour voir les photos et vidéos de vos amis.</p>
        <button
          className=" "
          onClick={() => {
            navigate("/register");
          }}
        >
          Register
        </button>
        <p>Vous avez un compte ? Connectez-vous</p>
        <p>Téléchargez l'application.</p>
      </div>
    </div>
  );
};

export default Login;
