
import { socket } from './../socket'
import {
    Row,
    Col,
    ListGroup,
    Badge,
    Form,
    Button,
    // Rename the import
  
  } from "react-bootstrap";
import Keyboard from "react-simple-keyboard";

import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";

  import data from '@emoji-mart/data'
// import Picker from '@emoji-mart/react'
import SpeechRecognition, {
  useSpeechRecognition
} from "react-speech-recognition";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { ChatBubbleOutline } from '@material-ui/icons';
import { useAuthDispatch, useAuthState } from '../context/AuthContext';
import { useMessageDispatch } from '../context/MessageContext';
import { baseUrl } from './../App';
// import Message from './Message';
import TopBar from './TopBar/TopBar';
import styled, { StyleSheetManager } from 'styled-components';
import axios from 'axios';
import { ConnectionState } from './ConnectionState';
import { Events } from './Events';
import { ConnectionManager } from './ConnectionManager';
import { MyForm } from './MyForm';
import { OnlineUsersState } from './OnlineUserState';
import { Fragment, useEffect, useRef, useState } from 'react';

const ChatBubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const UsersList = styled.div`
  flex: 1;
  background-color: #f7f7f7;
  padding: 10px;
  overflow-y: auto;
`;

const User = styled.div`
  padding: 10px;
  background-color: ${({ selected }) => (selected ? '#e0e0e0' : 'transparent')};
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Username = styled.div`
  font-weight: bold;
`;

const Content = styled.span`
  font-size: 14px;
  color: ${({ hascontent }) => (hascontent ? '#000' : '#888')};
`;

const MessageBox = styled.div`
  flex: 3;
  background-color: #fff;
  padding: 10px;
  overflow-y: auto;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const InfoText = styled.p`
  text-align: center;
`;

const Messenger = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MessagesBox = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const MessageInputBox = styled.div`
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
`;

const MessageInput = styled(Form.Control)`
  width: 100%;
  border-radius: 20px;
  background-color: #f2f2f2;
  border: none;
`;

const SendMessageButton = styled.i`
  cursor: pointer;

  &:hover {
    color: #1976d2;
  }
`;
export const MessengerContainer = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const UserListContainer = styled.div`
  flex: 1;
  background-color: #f2f2f2;
  padding: 20px;
  overflow-y: auto;
  @media (max-width: 768px) {
    flex: none;
    height: 40vh;
    overflow-y: auto;
  }
`;

export const MessageSectionContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  @media (max-width: 768px) {
    flex: none;
    height: 60vh;
  }
`;
const Emoticon = styled.span`
  &::before {
    content: 'ðŸ˜Š'; /* You can replace this with your desired emoji */
    margin-right: 5px; /* Adjust spacing as needed */
  }
`;
export const UserListItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background: linear-gradient( to right, #0A7CFF, hsl(212, 100%, 67%) );
  border-radius: 5px;
  border: 3px solid #0A7CFF;
  /* color:#ffffff; */
  font-size: 0.8rem; 
  cursor: pointer;
  padding: 0.25em 0.75em;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  transition: all 0.4s ease-in-out;
  box-shadow: 0px -1px 5px -3px rgb(106, 101, 145);
  -webkit-box-shadow: 0px -1px 36px -3px rgb(106, 101, 145);
  -moz-box-shadow: 0px -1px 36px -3px rgb(106, 101, 145);

  &:hover {
    background: -webkit-linear-gradient(
    45deg,
    rgb(133, 29, 186) 15%,
    rgba(255, 0, 224, 1) 100%
  );
  }
`;

export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;



export const LastMessage = styled.span`
  font-size: 12px;
  color: #777;
`;

export const MessageHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

export const MessageAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const OnlineStatus = styled.span`
  font-size: 12px;
  color: #777;
`;

export const OfflineStatus = styled.span`
  font-size: 12px;
  color: #777;
`;

export const MessageBody = styled.div`

  flex: 1;
  overflow-y: auto;
  padding: 10px 20px 0 0;
`;

export const Message = styled.div`

  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.outgoing === "true" ? "flex-end" : "flex-start"};
  margin-bottom: 10px;
`;

export const MessageContent = styled.div`
  background: -webkit-linear-gradient(
    45deg,
    rgb(133, 29, 186) 15%,
    rgba(255, 0, 224, 1) 100%
  );

  padding: 10px;
  border-radius: 5px;
`;

export const StyledSpan = styled.span`
  background-color: pink;
  padding: 5px;
  border-radius: 5px;
  color: #333;
`;

export const MessageTime = styled.span`
  font-size: 12px;
  color: #777;
`;


export const MessageFooter = styled.div`
  padding-top: 10px;


`;
export const MessageButton = styled.button`
  padding-top: 10px;
   &:hover {
  transform: scale(1.2);
}
`;
export const FormGroup = styled.div`
  background-color: rgb(235, 235, 235);
  border: 3px solid #d0d0d0;
  /* width: 60%; */
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  

`;

export const EmptyMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #777;
  font-size: 18px;
`;

export const Alert = styled.div`
  margin-bottom: 10px;
`;
 export const wrapperForm = styled.div`
display: flex !important;
background-color: aqua;
`;

const  ChatBubble = () => {

    const scrollRef = useRef(null);
    const [error, setError] = useState("");
    const PF = `${baseUrl}/images/`;
    const { finalTranscript, resetTranscript } = useSpeechRecognition();
    const [showKeyboard, setshowKeybord] = useState(false);
    const [layout, setLayout] = useState("default");
    const [showAlert, setShowAlert] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [status, setStatus] = useState(false);
    const keyboard = useRef();
    const messagesEndRef = useRef(null);
    const [scrollTop, setScrollTop] = useState(false);
    const toggleEmojiPicker = () => {
      setShowEmojiPicker(!showEmojiPicker);
    };
    const [isConnected, setIsConnected] = useState(socket.connected);
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const authDispatch = useAuthDispatch();
    const messageDispatch = useMessageDispatch();
    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [formattedMessages, setFormattedMessages] = useState([]);
    const { user } = useAuthState();
    const [content, setContent] = useState('');
    // const socketRef = useRef(null);
    const latestMessage = formattedMessages[formattedMessages.length - 1];
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const [onlineUsers, setOnlineUsers] = useState([]);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
      };
      useEffect(() => {
        if (scrollTop) return setScrollTop(false);
        scrollToBottom();
      }, [messages]);
      const handleShift = () => {
        const newLayoutName = layout === "default" ? "shift" : "default";
        setLayout(newLayoutName);
      };
      const onKeyPress = (button) => {
        console.log("Button pressed", button);
        //If you want to handle the shift and caps lock buttons
        if (button === "{shift}" || button === "{lock}") handleShift();
      };
      const onChange = (content) => {
        setContent(content);
        console.log("Input changed", content);
      };
      const keyboardToggle = (e) => {
        console.log("in keyboardToggle");
        if (showKeyboard === true) {
          setshowKeybord(false);
          console.log("picker not visible");
        } else {
          setshowKeybord(true);
          console.log("picker visible");
        }
      };
      
      useEffect(() => {
        if (finalTranscript !== "") {
          setShowAlert(false);
          // setInput(finalTranscript);
           setContent(finalTranscript);
    
          
          resetTranscript();
        }
      });
      const Speechtoinput = (e) => {
        setShowAlert(true);
        SpeechRecognition.startListening();
      };
      const handleEmojiSelect = (emoji) => {
        setContent(content + emoji.native);
      };
    // const socket = io('ws://localhost:4000');
    useEffect(() => {
        const getUser = (userId) => {
            return users.find((user) => user.userId === userId);
        };
        //   //send and get message
        socket.on("sendMessage", ({ from, to, content }) => {
            const user = getUser(to);
            socket.to(user.socketId).emit("getMessage", {
                from,
                content,
            });
        });
        // Socket events
        socket.on("login", (userId) => {
            messageDispatch({
                type: "login",
                payload: userId,
            });
        });
        // Don't forget to disconnect the socket when the component unmounts
        function onConnect() {
            console.log("Connected to socket;", socket.connected);
            socket.emit("addUser", user.id);
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        function onMesage(message) {
            setMessages(previous => [...previous, message]);
        }
        function ongetUsers(users) {
            setOnlineUsers(previous => [...previous, users]);
        }
        function onaddUser(users) {
            console.log("Connected to socket;", user.id);
            socket.emit("addUser", users);
            socket.emit('userConnected', user.id);

        }
        function onLine(userId) {
            setOnlineUsers(previous => [...previous, userId]);
        }
        function onuserOffline(userId) {
            setOnlineUsers((prevUsers) => prevUsers.filter((id) => id !== userId));
        }
        // Listen for the userOffline event and update the onlineUsers state


        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('sendMessage', onMesage);
        socket.on('userOnline', onLine);
        socket.on('getUsers', ongetUsers);
        socket.on('addUser', onaddUser);
        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('sendMessage', onMesage);
            socket.off('userOnline', onLine);
            socket.off('getUsers', ongetUsers);
            socket.on('userOffline', onuserOffline);
        };
    }, []);
    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await fetch(`${baseUrl}/api/users/`, { headers });
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.log(error);
            }
        };

        getUsers();



        socket.on('message', (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
            setLoading(false);
        });

        return () => {
            socket.close();
        };
    }, []);

    useEffect(() => {
        if (selectedUser) {
            fetch(`${baseUrl}/api/messages/${selectedUser.username}`, { headers })
                .then((response) => response.json())
                .then((data) => setMessages(data))
                .catch((error) => console.log(error));
        }
    }, [selectedUser]);

    useEffect(() => {
        const newMessages = messages?.filter(
            (message) => message.to === user.username || message.from === user.username
        );

        const otherUsers = newMessages?.map((message) =>
            message.to === user.username ? message.from : message.to
        );

        const uniqueOtherUsers = [...new Set(otherUsers)];

        const formattedMessages = uniqueOtherUsers?.map((username) => {
            const messagesWithUser = newMessages.filter(
                (message) => message.to === username || message.to === username
            );

            const lastMessage = messagesWithUser[messagesWithUser.length - 1]?.content;

            const formattedMessage = {
                username,
                message: {
                    content: lastMessage,
                    createdAt: messagesWithUser[messagesWithUser.length - 1]?.createdAt,
                },
            };

            return formattedMessage;
        });

        const sortedMessages = formattedMessages.sort((a, b) => {
            const aTimestamp = a.message.createdAt || 0;
            const bTimestamp = b.message.createdAt || 0;
            return bTimestamp - aTimestamp;
        });

        setFormattedMessages(sortedMessages);
    }, [messages]);

    const submitMessage = async (e) => {
        e.preventDefault();

        if (!selectedUser) {
            setError('Recipient is required');
            return;
        }

        if (content.trim() === '') {
            setError('Message content is required');
            return;
        }

        try {
            const message = {
                to: selectedUser.username,
                from: user.username,
                content,
            };
            const res = await axios.post(`${baseUrl}/api/messages`, message);
            const newMessage = {
                to: res.data.to,
                from: res.data.from,
                content: res.data.content,
            };

            socket.emit('sendMessage', newMessage);
            setContent('');
            setError('');

            setMessages((prevMessages) => [...prevMessages, newMessage]);
        } catch (error) {
            console.log(error);
            setError('Failed to send message');
        }
    };
    // Correctly update online users when users list changes
    useEffect(() => {
        const updateOnlineUsers = (users) => {
            const updatedUsers = users.map((user) => ({
                ...user,
                online: socket.connected && user.socketId === socket.id,
            }));
            setOnlineUsers(updatedUsers);
        };

        const handleGetUsers = (users) => {
            updateOnlineUsers(users);
        };

        socket.on("getUsers", handleGetUsers);

        // Socket cleanup
        return () => {
            socket.off("getUsers", handleGetUsers);
        };
    }, [users]);
    // Assuming you have a function to handle logout, e.g., logoutUser
    async function handleLogout() {
        try {
            // Call the logout API endpoint on the server
            const response = await axios.post(`${baseUrl}/api/logout`, { headers });

            // Check if the response is successful
            if (response.ok) {
                const data = await response.json();
                console.log(data.message); // Logout successful

                // Perform any additional client-side logout actions if needed
                // For example, clear local storage, update state, etc.
            } else {
                const errorData = await response.json();
                console.error(errorData.error);
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
    }

    const logout = () => {
        authDispatch({ type: 'LOGOUT' });
        window.location.href = '/login';
    };

    let selectedChatMarkup;

    if (!messages) {
        selectedChatMarkup = <p className="info-text">Select a friend</p>;
    } else if (messages.length === 0) {
        selectedChatMarkup = (
            <p className="info-text">You are now connected! Send your first message</p>
        );
    } else {
        selectedChatMarkup = messages?.map((message, index) => (
            <Fragment key={message.id}>
                <Message message={message} />
                {index === messages.length - 1 && (
                    <div className="invisible">
                        <hr className="m-0" />
                    </div>
                )}
            </Fragment>
        ));
    }
    const UserList = ({ users, onlineUsers }) => {
        return (
          <ListGroup>
            
            {users.map((user) => (
              <UserListItem
                key={user.id}
                // action="true"
                onClick={() => setSelectedUser(user)}
                active={
                  selectedUser && selectedUser.id === user.id ? "true" : undefined
                }
                outgoing={
                  selectedUser && selectedUser.id === user.id ? "true" : "false"
                }
              >
                
                <div
                  className={`chatOnlineBadge ${
                    onlineUsers[0]?.online && onlineUsers[0]?.userId === user.id
                      ? "online"
                      : "offline"
                  }`}
                ></div>
                <UserAvatar
                  src={
                    user.profilePicture
                      ? PF + user.profilePicture
                      : PF + "/noAvatar.png"
                  }
                  alt="User Avatar"
                />
                <Username>{user.username}</Username>
    
                {latestMessage && selectedUser && selectedUser.id === user.id && (
                  <div key={latestMessage.id}>
                    <LastMessage>: {latestMessage.message?.content}</LastMessage>
                    <Emoticon /> {/* Use the Emoticon styled component here */}
                  </div>
                )}
              </UserListItem>
            ))}
          </ListGroup>
        );
      };
      const handleUserClick = (user) => {
        setSelectedUser(user);
        setError("");
      };
    return (
    //     <ChatBubbleContainer>
    //         <TopBar />

    //         <div className="App">
    //             <ConnectionState isConnected={isConnected} />

    //             <OnlineUsersState onlineUsers={onlineUsers} />
    //             <Events messages={messages} />

    //             <ConnectionManager />
    //             <MyForm />
    //         </div>
    //         <button onClick={handleLogout}>Logout</button>
    //         <div>
    //             {users.map((user) => (
    //                 <div key={user.id}>
    //                     {user.username} {user.isOnline ? '(Online)' : '(Offline)'}
    //                 </div>
    //             ))}
    //         </div>
    //         <UsersList>
    //             <h5 className="mb-3">Friends</h5>
    //             {users.map((user) => (
    //                 <User
    //                     key={user.id}
    //                     selected={selectedUser?.username === user.username}
    //                     onClick={() => setSelectedUser(user)}
    //                 >
    //                     <UserInfo>
    //                         <Username>{user.username}</Username>
    //                         <Content hascontent={formattedMessages[0]?.username === user.username}>
    //                             {formattedMessages[0]?.username === user.username ? (
    //                                 <span
    //                                     className={formattedMessages[0]?.message.content ? 'content' : 'no-message'}
    //                                 >
    //                                     {formattedMessages[0]?.message.content || 'No messages'}
    //                                 </span>
    //                             ) : null}
    //                         </Content>
    //                     </UserInfo>
    //                 </User>
    //             ))}
    //         </UsersList>
    //         <MessageBox>
    //             {loading ? (
    //                 <SpinnerContainer>
    //                     <ChatBubbleOutline color="primary" />
    //                 </SpinnerContainer>
    //             ) : (
    //                 <Messages>
    //                     {selectedUser ? (
    //                         messages.length === 0 ? (
    //                             <InfoText>You are now connected! Send your first message</InfoText>
    //                         ) : (
    //                             messages.map((message, index) => <Message key={index} message={message} />)
    //                         )
    //                     ) : (
    //                         <InfoText>Select a friend</InfoText>
    //                     )}
    //                 </Messages>
    //             )}
    //         </MessageBox>
    //         <Messenger>
    //             <MessagesBox>
    //                 <Messages>
    //                     {selectedUser ? (
    //                         messages.length === 0 ? (
    //                             <InfoText>You are now connected! Send your first message</InfoText>
    //                         ) : (
    //                             messages.map((message, index) => <Message key={index} message={message} />)
    //                         )
    //                     ) : (
    //                         <InfoText>Select a friend</InfoText>
    //                     )}
    //                 </Messages>
    //             </MessagesBox>
    //             <MessageInputBox>
    //                 <Form onSubmit={submitMessage}>
    //                     <Form.Group className="my-3 d-flex align-items-center">
    //                         <MessageInput
    //                             type="text"
    //                             placeholder="Type a message ..."
    //                             value={content}
    //                             onChange={(e) => setContent(e.target.value)}
    //                         />
    //                         <SendMessageButton
    //                             className="fas fa-paper-plane fa-2x text-primary mx-2"
    //                             onClick={submitMessage}
    //                         >
    //                             Send
    //                         </SendMessageButton>
    //                     </Form.Group>
    //                 </Form>
    //             </MessageInputBox>
    //         </Messenger>
    //     </ChatBubbleContainer>
    <div>
    {" "}
    {/* <Topbar messages={messages} users={users} /> */}
    <MessengerContainer>
      <UserListContainer>
        <ListGroup.Item>
          <div>
            <h6>UserList </h6>
            <UserList users={users} onlineUsers={onlineUsers} />
          </div>
          <h6>User Online</h6>

          <h6>{user.username}</h6>
          {user.username === selectedUser?.username && (
            <Badge variant={onlineUsers[0]?.online ? "success" : "danger"}>
              {onlineUsers[0]?.online ? "Online1" : "Offline1"}
            </Badge>
          )}
        </ListGroup.Item>
      </UserListContainer>
      <MessageSectionContainer>
        {selectedUser ? (
          <>
            <MessageHeader>
              <MessageAvatar
                src={
                  selectedUser.profilePicture
                    ? PF + selectedUser.profilePicture
                    : PF + "/noAvatar.png"
                }
                alt="Selected User Avatar"
              />
              <div>
                <StyledSpan>
                  Chat with {selectedUser.username}

                  {latestMessage && (
                    <div key={latestMessage.id}>
                      <StyledSpan>
                        {latestMessage.username}:{" "}
                        {latestMessage.message?.content}
                      </StyledSpan>
                    </div>
                  )}
                </StyledSpan>

                {user.username === selectedUser?.username &&
                onlineUsers[0]?.online ? (
                  <OnlineStatus>Status Online</OnlineStatus>
                ) : (
                  <OfflineStatus>Status Offline</OfflineStatus>
                )}
              </div>
            </MessageHeader>
            <MessageBody>
              {messages.length > 0 ? (
                messages.map((message) => (
                  <Message
                    key={message.id}
                    outgoing={
                      message.from === user.username ? "true" : "false"
                    }
                  >
                    <MessageContent>{message.content}</MessageContent>
                    <MessageTime>{message.createdAt}</MessageTime>
                  </Message>
                ))
              ) : (
                <EmptyMessage>No messages yet</EmptyMessage>
              )}
              <div
                    // className={dark ? "scrolltobottomdark" : "scrolltobottom"}
                  >
                    <Button title="scroll to bottom" onClick={scrollToBottom}>
                      <KeyboardArrowDownIcon
                        // className={dark ? "scrollicondark" : "scrollicon"}
                        style={{ width: "20px", height: "40px" }}
                      />
                    </Button>
                  </div>
              <div ref={scrollRef}></div>

            </MessageBody>

            <MessageFooter>

               <div>


    
              <Form  onSubmit={submitMessage}>

                {error && <Alert variant="danger">{error}</Alert>}
                {showKeyboard && (
                          <span
                            // className={`${
                            //   dark ? "KeyboardPicker_dark" : "KeyboardPicker"
                            // }`}
                          >
                            {/* <input
                                                              onChange={onChangeInput}
                                                          /> */}
                            <Keyboard
                              keyboardRef={(r) => (keyboard.current = r)}
                              layoutName={layout}
                              onChange={onChange}
                              onKeyPress={onKeyPress}
                            />
                          </span>
                        )}
                <FormGroup>
                <button
                          // className={`addfiles ${dark ? "darkButton" : ""}`}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
        <InsertEmoticonIcon onClick={toggleEmojiPicker}/>
        <button className="KeyboardToggle">
                          <i
                            className="fa fa-keyboard-o"
                            onClick={keyboardToggle}
                          ></i>
                        </button>
                     
                        {/* <input
                          type="text"
                          onChange={(e) => {
                            setContent(e.target.value);
                            setStatus(false);
                          }}
                        /> */}
                        {/* <CopyToClipboard
                          text={value}
                          onCopy={() => setStatus(true)}
                        >
                          <button className="copy">
                            {" "}
                            <i className="fa fa-copy"></i>
                          </button>
                        </CopyToClipboard> */}
                        {status && (
                          <p className="copied_status"> Copied!!!</p>
                        )}    
                  <Form.Control
                    as="textarea"
                    className="rounded-pill"
                    rows={1}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    
                  />
                                            <div className="speak">
                          <button onClick={Speechtoinput}>
                            <i className="fa fa-microphone"></i>
                          </button>
                          {showAlert && (
                            <span className="Speaknow_alert">iWA DWI </span>
                          )}
                        </div>
                
                <MessageButton variant="primary" type="submit">
                <i className="fa fa-paper-plane"></i>   
                </MessageButton>
               
                </FormGroup>

              </Form>
            {/* <Picker data={data} onEmojiSelect={console.log} /> */}

{/* Add the emoji picker */}
{showEmojiPicker && (
// <Picker
//   data={data}
//   set="facebook"
//   onEmojiSelect={handleEmojiSelect}
//   showPreview={false}
//   showSkinTones={false}
//   style={{
//     position: 'absolute',
//     bottom: '100px', // Adjust the position as needed
//     right: '20px', // Adjust the position as needed
//   }}
// />
<button data={data} onEmojiSelect={handleEmojiSelect}
showPreview={false}
showSkinTones={false} 
    style={{
    position: 'absolute',
    bottom: '100px', // Adjust the position as needed
    right: '20px', // Adjust the position as needed
  }}
/> 

)}

</div>

            </MessageFooter>
          </>
        ) : (
          <EmptyMessage ref={scrollRef}>
            Select a user to start messaging
          </EmptyMessage>
        )}
      </MessageSectionContainer>
    </MessengerContainer>

  </div>
    );
};




export default  ChatBubble;