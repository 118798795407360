
import { socket } from './../socket'
import { ConnectionState } from './ConnectionState';
import { Events } from './Events';
import { ConnectionManager } from './ConnectionManager';
import { MyForm } from './MyForm';
/**/
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { ChatBubbleOutline } from '@material-ui/icons';
import { useAuthDispatch, useAuthState } from '../context/AuthContext';
import { useMessageDispatch } from '../context/MessageContext';
// import { io } from 'socket.io-client';
import { baseUrl } from './../App';
import Message from './Message';
import TopBar from './TopBar/TopBar';
import styled, { StyleSheetManager } from 'styled-components';
import axios from 'axios';
import { OnlineUsersState } from './OnlineUserState';
const ChatBubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const UsersList = styled.div`
  flex: 1;
  background-color: #f7f7f7;
  padding: 10px;
  overflow-y: auto;
`;

const User = styled.div`
  padding: 10px;
  background-color: ${({ selected }) => (selected ? '#e0e0e0' : 'transparent')};
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Username = styled.div`
  font-weight: bold;
`;

const Content = styled.span`
  font-size: 14px;
  color: ${({ hascontent }) => (hascontent ? '#000' : '#888')};
`;

const MessageBox = styled.div`
  flex: 3;
  background-color: #fff;
  padding: 10px;
  overflow-y: auto;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const InfoText = styled.p`
  text-align: center;
`;

const Messenger = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MessagesBox = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const MessageInputBox = styled.div`
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
`;

const MessageInput = styled(Form.Control)`
  width: 100%;
  border-radius: 20px;
  background-color: #f2f2f2;
  border: none;
`;

const SendMessageButton = styled.i`
  cursor: pointer;

  &:hover {
    color: #1976d2;
  }
`;
function MyComponent() {
    const [fooEvents, setFooEvents] = useState([]);
    const [isConnected, setIsConnected] = useState(socket.connected);
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const authDispatch = useAuthDispatch();
    const messageDispatch = useMessageDispatch();
    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [formattedMessages, setFormattedMessages] = useState([]);
    // const { user } = useAuthState();
    const user = JSON.parse(localStorage.getItem("user"));
    const [content, setContent] = useState('');
    const [error, setError] = useState('');
    // const socketRef = useRef(null);

    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const [onlineUsers, setOnlineUsers] = useState([]);
    //     const userId = user.userId;
    // //     socket.current.on("getMessage", (data) => {
    // //       socket.current.emit("login", userId);
    // //       setArrivalMessage({
    // //         // sender: data.senderId,
    // //         // text: data.text,
    // //         to: data.to,
    // //         content: data.content,
    // //         createdAt: Date.now(),
    // //       });
    // //       console.log(data);
    // //     });
    // //   }, []);
    // //   // const members = Users;
    // //   useEffect(() => {
    // //     arrivalMessage &&
    // //       currentChat?.Users.includes(arrivalMessage.to) &&
    // //       setMessages((prev) => [prev, arrivalMessage]);
    // //   }, [arrivalMessage, currentChat]);

    useEffect(() => {

        const fetchUsers = async () => {
            try {
                const response = await fetch(`${baseUrl}/api/users/`, { headers });
                const data = await response.json();
                setUsers(data);
                localStorage.setItem("users", JSON.stringify(data));
            } catch (error) {
                console.log(error);
            }
        };

        // Fetch users and connect socket only once
        fetchUsers();
        const getUser = (userId) => {
            return users.find((user) => user.userId === userId);
        };
        //   //send and get message
        socket.on("sendMessage", ({ from, to, content }) => {
            const user = getUser(to);
            socket.to(user.socketId).emit("getMessage", {
                from,
                content,
            });
        });
        // Socket events
        socket.on("login", (userId) => {
            messageDispatch({
                type: "login",
                payload: userId,
            });
        });
        // socket.current.emit("addUser", user.id);
        function onConnect() {
            console.log("Connecteiet;", user.id);
            socket.emit("addUser", user.id);
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        function onFooEvent(value) {
            setFooEvents((previous) => [...previous, value]);
        }

        function ongetUsers(users) {
            setOnlineUsers(previous => [...previous, users]);
        }

        function onLine(userId) {
            setOnlineUsers((previous) => [...previous, userId]);
        }

        function onaddUser(users) {
            console.log("Connected to socket;", user.id);
            socket.emit("addUser", users);
            socket.emit('userConnected', user.id);

        }

        socket.on('connect', onConnect);

        socket.on('disconnect', onDisconnect);
        socket.on('foo', onFooEvent);
        socket.on('userOnline', onLine);
        socket.on('getUsers', ongetUsers);
        socket.on('addUser', onaddUser);

        // Socket cleanup
        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('foo', onFooEvent);
            socket.off('userOnline', onLine);
            socket.off('getUsers', ongetUsers);
        };
    }, []);
    /**admin***/
    // Correctly update online users when users list changes
    useEffect(() => {
        const updateOnlineUsers = (users) => {
            const updatedUsers = users.map((user) => ({
                ...user,
                online: socket.connected && user.socketId === socket.id,
            }));
            setOnlineUsers(updatedUsers);
        };

        const handleGetUsers = (users) => {
            updateOnlineUsers(users);
        };

        socket.on("getUsers", handleGetUsers);

        // Socket cleanup
        return () => {
            socket.off("getUsers", handleGetUsers);
        };
    }, [users]);
    useEffect(() => {
        if (selectedUser) {
            fetch(`${baseUrl}/api/messages/${selectedUser.username}`, { headers })
                .then((response) => response.json())
                .then((data) => setMessages(data))
                .catch((error) => console.log(error));
        }
    }, [selectedUser]);
    const submitMessage = async (e) => {
        e.preventDefault();

        if (!selectedUser) {
            setError('Recipient is required');
            return;
        }

        if (content.trim() === '') {
            setError('Message content is required');
            return;
        }

        try {
            const message = {
                to: selectedUser.username,
                from: user.username,
                content,
            };
            const res = await axios.post(`${baseUrl}/api/messages`, message);
            const newMessage = {
                to: res.data.to,
                from: res.data.from,
                content: res.data.content,
            };

            socket.emit('sendMessage', newMessage);

            setContent('');
            setError('');

            setMessages((prevMessages) => [...prevMessages, newMessage]);
        } catch (error) {
            console.log(error);
            setError('Failed to send message');
        }
    };
    // Assuming you have a function to handle logout, e.g., logoutUser
    async function handleLogout() {
        try {
            // Call the logout API endpoint on the server
            const response = await axios.post(`${baseUrl}/api/logout`);

            // Check if the response is successful
            if (response.ok) {
                const data = await response.json();
                console.log(data.message); // Logout successful

                // Perform any additional client-side logout actions if needed
                // For example, clear local storage, update state, etc.
            } else {
                const errorData = await response.json();
                console.error(errorData.error);
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
    }

    const logout = () => {
        authDispatch({ type: 'LOGOUT' });
        window.location.href = '/login';
    };

    let selectedChatMarkup;

    if (!messages) {
        selectedChatMarkup = <p className="info-text">Select a friend</p>;
    } else if (messages.length === 0) {
        selectedChatMarkup = (
            <p className="info-text">You are now connected! Send your first message</p>
        );
    } else {
        selectedChatMarkup = messages?.map((message, index) => (
            <Fragment key={message.id}>
                <Message message={message} />
                {index === messages.length - 1 && (
                    <div className="invisible">
                        <hr className="m-0" />
                    </div>
                )}
            </Fragment>
        ));
    }


    return (
        <ChatBubbleContainer>
            <button onClick={handleLogout}>Logout</button>
            <div className="App">
                <ConnectionState isConnected={isConnected} />

                <OnlineUsersState onlineUsers={onlineUsers} />
                {/* <Events messages={messages} /> */}

                <ConnectionManager />
                <MyForm />
            </div>

            <div>
                {users.map((user) => (
                    <div key={user.id}>
                        {user.username} {user.isOnline ? '(Online)' : '(Offline)'}
                    </div>
                ))}
            </div>
            <UsersList>
                <h5 className="mb-3">Friends</h5>
                {users.map((user) => (
                    <User
                        key={user.id}
                        selected={selectedUser?.username === user.username}
                        onClick={() => setSelectedUser(user)}
                    >
                        <UserInfo>
                            <Username>{user.username}</Username>
                            <Content hascontent={messages[0]?.from === user.username}>
                                {messages[0]?.from === user.username ? (
                                    <span
                                        className={messages[0].content ? 'content' : 'no-message'}
                                    >
                                        {messages[0].content || 'No messages'}
                                    </span>
                                ) : null}
                            </Content>
                        </UserInfo>
                    </User>
                ))}
            </UsersList>
            <MessageBox>
                {loading ? (
                    <SpinnerContainer>
                        <ChatBubbleOutline color="primary" />
                    </SpinnerContainer>
                ) : (
                    <Messages>
                        {selectedUser ? (
                            messages.length === 0 ? (
                                <InfoText>You are now connected! Send your first message</InfoText>
                            ) : (
                                selectedChatMarkup
                            )
                        ) : (
                            <InfoText>Select a friend</InfoText>
                        )}
                    </Messages>
                )}
            </MessageBox>
            <Messenger>
                <MessagesBox>
                    <Messages>
                        {selectedUser ? (
                            messages.length === 0 ? (
                                <InfoText>You are now connected! Send your first message</InfoText>
                            ) : (
                                selectedChatMarkup
                            )
                        ) : (
                            <InfoText>Select a friend</InfoText>
                        )}
                    </Messages>
                </MessagesBox>
                <MessageInputBox>
                    <Form onSubmit={submitMessage}>
                        <Form.Group className="my-3 d-flex align-items-center">
                            <MessageInput
                                type="text"
                                placeholder="Type a message ..."
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                            />
                            <SendMessageButton
                                className="fas fa-paper-plane fa-2x text-primary mx-2"
                                onClick={submitMessage}
                            >
                                Send
                            </SendMessageButton>
                        </Form.Group>
                    </Form>
                </MessageInputBox>
            </Messenger>
        </ChatBubbleContainer>
    );
};

const ChatBubbleWrapper = () => (
    <StyleSheetManager shouldForwardProp={(prop) => prop !== 'hascontent'}>
        <MyComponent />
    </StyleSheetManager>
);

export default ChatBubbleWrapper;
