import { io } from 'socket.io-client';
const nodeEnv = process.env.NODE_ENV;
let ioUrl;

if (nodeEnv === 'production') {
  ioUrl = process.env.REACT_APP_IO_URL || 'https://sds-2a76.onrender.com';
} else {
  ioUrl = process.env.REACT_APP_IO_URL || 'http://localhost:4000';
}

export const baseUrl = ioUrl;

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:4000';

export const socket = io(baseUrl, {
    autoConnect: false
});