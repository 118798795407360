import React from 'react';
import './HomePage.css'; // Import the CSS file for styling
// import ChatBubble from '../../components/ChatBubble';
import TopBar from '../../components/TopBar/TopBar';
import MyComponent from '../../components/MyComponent';
import ChatBubble from '../../components/ChatBubble';


const HomePage = () => {
    return (
        <div className="home-page">

            <ChatBubble />
            <footer>
                <p>&copy; 2023 My Website. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default HomePage;
