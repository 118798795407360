// TopBar.jsx

import React from "react";
// import "./TopBar.css";
import { useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import styled from "styled-components";
import { useAuthDispatch } from "../../context/AuthContext";

const TopBarContainer = styled.header`
  background-color: #1976d2;
  padding: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TopBarMenu = styled.nav`
  ul {
    list-style: none;
    display: flex;
    align-items: center;

    li {
      margin-right: 20px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const DotMenu = styled.div`
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;
`;

const TopBar = ({ messagesCount, usersOnline, onDotMenuClick }) => {
  let navigate = useNavigate();
  const authDispatch = useAuthDispatch();
  const handleLogout = () => {
    // Dispatch the 'LOGOUT' action to clear the user token and state
    authDispatch({ type: "LOGOUT" });
    // Redirect to the login page
    navigate("/login");
  };
  return (
    <TopBarContainer>
      <div className="container">
        <TopBarMenu>
          <ul>
            <li
              onClick={() => {
                navigate("/");
              }}
            >
              Home
            </li>
            {/* <li
              onClick={() => {
                navigate("/chatbubble");
              }}
            >
              ChatBubble
            </li>
            <li>About</li> */}
            <li
              onClick={() => {
                navigate("/contactus");
              }}
            >
              Contact
            </li>
            <li className="logout-button" onClick={handleLogout}>
            Logout
          </li>
          </ul>
       
        </TopBarMenu>
        <div className="ml-2">
        {/* <Badge variant="light">  <DotMenu onClick={onDotMenuClick} />  {messagesCount} Messages  
        
            </Badge> */}
          <Badge variant="light" className="ml-2">
            {usersOnline} Online   <DotMenu onClick={onDotMenuClick} />
          </Badge>
        </div>
      </div>
    </TopBarContainer>
  );
};

export default TopBar;
