import React from 'react';
import './HomePage.css'; // Import the CSS file for styling
import TopBar from './../../components/TopBar/TopBar';


const ContactUs = () => {
    return (
        <div className="home-page">
            <TopBar />
            <header>
                <h1>Welcome to the Home Page</h1>
                <nav>
                    <ul>
                        <li>Home</li>
                        <li>About</li>
                        <li>Contact</li>
                        <li>Services</li>
                        <li>Gallery</li>

                    </ul>
                </nav>
            </header>

            <main>
                <section>
                    <h2>About Us</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                        pharetra euismod felis at eleifend. Donec at metus tincidunt,
                        eleifend magna sit amet, tincidunt justo. Fusce vulputate mi a
                        erat ullamcorper, sed luctus neque viverra. Cras rhoncus, ligula
                        non consectetur vulputate, justo eros pharetra magna, et finibus
                        odio enim sed est.
                    </p>
                </section>

                <section>
                    <h2>Contact Us</h2>
                    <form className="contact-form">
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="name" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="email" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message:</label>
                            <textarea id="message" name="message"></textarea>
                        </div>
                        <button type="submit">Send Message</button>
                    </form>
                </section>

                {/* Rest of the sections */}

            </main>

            <footer>
                <p>&copy; 2023 My Website. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default ContactUs;
