import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './pages/Home/HomePage';
import Register from './pages/register/Register';
import TopBar from './components/TopBar/TopBar';
import ChatBubble from './components/ChatBubble';
import Login from './pages/login/Login';
import ContactUs from './pages/Home/ContactUs';
import { MessageProvider } from './context/MessageContext';
import './App.css';
import "./App.scss";

const nodeEnv = process.env.NODE_ENV;
let ioUrl;

if (nodeEnv === 'production') {
  ioUrl = process.env.REACT_APP_IO_URL || 'https://sds-2a76.onrender.com';
} else {
  ioUrl = process.env.REACT_APP_IO_URL || 'http://localhost:4000';
}

export const baseUrl = ioUrl;

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [messages, setMessages] = useState([]);

  const toggleLogin = () => {
    setIsLoggedIn(prevState => !prevState);
  };

  const handleRegisterSubmit = (username, password) => {
    // Perform registration logic here
    // Example: Call an API to register the user
    console.log('Register:', username, password);
    // Update the isLoggedIn state if the registration is successful
    setIsLoggedIn(true);
  };

  const handleLoginSubmit = (username, password) => {
    // Perform login logic here
    // Example: Call an API to authenticate the user
    console.log('Login:', username, password);

    // Check if the username and password match
    const user = JSON.parse(localStorage.getItem("user")) || null;
    if (user && user.username === username && user.password === password) {
      // Update the isLoggedIn state if the login is successful
      setIsLoggedIn(true);
    } else {
      // Handle unsuccessful login
      console.log('Invalid credentials');
    }
  };

  const handleSendMessage = message => {
    setMessages(prevMessages => [...prevMessages, message]);
  };

  useEffect(() => {
    // Check if the user is already logged in based on the local storage
    const user = JSON.parse(localStorage.getItem("user")) || null;
    if (user) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <Router>
      <MessageProvider>
        <Routes>
          <Route path="/chatbubble" element={<ChatBubble />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <>
                  <TopBar isLoggedIn={isLoggedIn} onToggleLogin={toggleLogin} />
                  <HomePage
                    onToggleLogin={toggleLogin}
                    onSendMessage={handleSendMessage}
                    messages={messages}
                  />
                </>
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path="/register"
            element={
              <div className="app-container">
                <div className="left-image">
                  <img src="/assets/1.png" alt="Smartphone" />
                </div>
                <div className="right-content">
                  <div className="right-image">
                    <Register onSubmit={handleRegisterSubmit} />
                  </div>
                </div>
              </div>
            }
          />
          <Route path="/login" element={<Login onSubmit={handleLoginSubmit} />} />
        </Routes>
      </MessageProvider>
    </Router>
  );
};

export default App;
