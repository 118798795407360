// import React from 'react';
// import { createRoot } from "react-dom/client";

 import { AuthProvider } from './context/AuthContext';
 import { MessageProvider } from './context/MessageContext';
// import App from './App';
// const container = document.getElementById("root");
// const root = createRoot(container);
// root.render(
//   <React.StrictMode>
//     <AuthProvider>
//       <MessageProvider>
//         <App />
//       </MessageProvider>
//     </AuthProvider>
//   </React.StrictMode>,

// );
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>

     <AuthProvider>
       <MessageProvider>
         <App />
       </MessageProvider>
     </AuthProvider>
  </React.StrictMode>
  ,
  document.getElementById("root")
);
